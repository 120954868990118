<template>
    <el-card shadow="never" :class="['act-card', getActStatus(1)]">
        <el-form-item :label="$t(`account.profile.act-title-${act.id}`)" :prop="act.type">
            <div class="status">
                <span>Status: </span>
                <el-tag size="small" :type="getActStatus(0)"
                    >{{ $t(`account.profile.act-status-${getActStatus(1)}`) }}
                </el-tag>
            </div>
            <template v-if="!hasActUploaded || isActRefused">
                <el-upload
                    :name="`docfile-${act.id}`"
                    accept="image/*,application/pdf"
                    class="file-upload fullWidth"
                    :ref="`upload-${act.id}`"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :auto-upload="false"
                >
                    <el-button slot="trigger" class="fullWidth" size="small" type="primary" plain icon="el-icon-upload">
                        {{ isActRefused ? $t('account.profile.pick-another-file') : $t('account.profile.pick-file') }}
                    </el-button>
                    <div
                        slot="tip"
                        class="document-tip el-upload__tip"
                        v-html="$t(`account.profile.act-description-${act.id}`)"
                    ></div>
                </el-upload>
            </template>
            <template v-else>
                <!-- <el-button class="fullWidth" size="small" type="primary" plain icon="el-icon-download"
					@click="downloadUploadedAct">
					{{$t('account.profile.download-uploaded-act')}}</el-button> -->
                <div class="document-tip el-upload__tip" v-html="$t(`account.profile.act-description-${act.id}`)"></div>
            </template>
            <div class="reject-reason" v-if="isActRefused">
                <span>{{ $t('account.profile.act-refuse-reason') }}:</span>
                <span v-html="act.document.refuse_reason"></span>
            </div>
        </el-form-item>
    </el-card>
</template>

<script>
import { call } from 'vuex-pathify';
export default {
    props: ['act'],
    data: () => ({
        allowedFileTypes: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/gif', 'image/png']
    }),
    computed: {
        hasActUploaded() {
            return this.act.document;
        },
        isActRefused() {
            return this.act.document && this.act.document.status == -1;
        }
    },
    methods: {
        uploadActFile: call('account/UploadActFile'),
        downloadAct: call('account/DownloadUploadedAct'),
        async downloadUploadedAct() {
            try {
                const res = await this.downloadAct(this.act.document.hash);

                const linkSource = `data:${res.message.type};base64,${res.message.data}`;
                const downloadLink = document.createElement('a');
                const fileName = `${this.$t(`account.profile.act-title-${this.act.id}`)}.${res.message.extension}`;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
            } catch (err) {
                console.log(err);
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'downloadUploadedAct',
                        params: {
                            hash: this.act.document.hash
                        }
                    }
                });
            }
        },
        getActStatus(returnCase = 1) {
            switch (true) {
                case this.hasActUploaded && parseInt(this.act.document.status) === 1:
                    return returnCase === 1 ? 'signed' : 'success';
                    break;
                case this.hasActUploaded && parseInt(this.act.document.status) === 0:
                    return returnCase === 1 ? 'waiting' : 'primary';
                    break;
                case this.hasActUploaded && parseInt(this.act.document.status) === -1:
                    return returnCase === 1 ? 'canceled' : 'danger';
                    break;

                default:
                    return returnCase === 1 ? 'not-uploaded' : 'info';
            }
        },
        uploadFile() {
            const file = document.querySelector(`input[name="docfile-${this.act.id}"]`).files;
            if (file.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Fisier invalid sau inexistent. Incearca din nou!'
                });
                return false;
            }

            if (file[0].size / 1024 / 1024 > 5) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('account.profile.max-act-size')
                });
                document.querySelector(`input[name="docfile-${this.act.id}"]`).value = '';
                return false;
            }

            if (!this.allowedFileTypes.includes(file[0].type)) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('register.documents.file-message')
                });
                document.querySelector(`input[name="docfile-${this.act.id}"]`).value = '';
                return false;
            }

            this.$confirm(this.$t('account.profile.act-upload-confirm'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning'
            })
                .then(async () => {
                    const formData = new FormData();
                    formData.append('file', file[0]);
                    formData.append('actid', this.act.id);
                    await this.uploadActFile(formData);
                    this.sbMsg({
                        type: 'success',
                        message: this.$t('account.profile.act-upload-success')
                    });
                    // this.$emit('fileUpload');
                    this.removeListener();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        addListener() {
            const file = document.querySelector(`input[name="docfile-${this.act.id}"]`);

            if (file != null) {
                file.addEventListener('change', this.uploadFile);
            }
        },
        removeListener() {
            const file = document.querySelector(`input[name="docfile-${this.act.id}"]`);
            if (file != null) {
                file.removeEventListener('change', () => {});
            }
        }
    },
    mounted() {
        this.addListener();
    },
    beforeDestroy() {
        this.removeListener();
    }
};
</script>

<style lang="scss" scoped>
.status {
    margin-bottom: 10px;
}

.reject-reason {
    color: #dd6161;
}

::v-deep .el-form-item {
    margin-bottom: 0;
}

.act-card ::v-deep .el-card__body {
    min-height: 230px;
}

.act-card {
    border-top: 7px solid grey;
    border-radius: 10px 10px 0 0;

    &.signed {
        border-top-color: #67c23a;
    }

    &.waiting {
        border-top-color: #ff601d;
    }

    &.canceled {
        border-top-color: #f56c6c;
    }
}

.el-upload__tip {
    line-height: 1.5;
}
</style>
